import * as bootstrap from 'bootstrap';
import Glide from '@glidejs/glide';
// import Chart from 'chart.js/auto';

// TOASTS
// ==============================================================================================
const toastElList = document.querySelectorAll('.toast');
const toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl));

const toastTrigger = document.querySelectorAll('.lToastTrigger');
const toastLiveExample = document.getElementById('liveToast')

if (toastTrigger) {

  toastTrigger.forEach(function (item) {
    item.addEventListener('click', (e) => {
      let target = item.dataset.target;
      let targetToast = document.getElementById(target);

      let toast = new bootstrap.Toast(targetToast);

      toast.show();
    })
  })
}

toastList.forEach(function (item) {
  item.show();
})
// ==============================================================================================
// end of TOASTS

// POPOVERS
// ==============================================================================================
let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
});
// ==============================================================================================
// end of POPOVERS

// DATEPICKER
// ==============================================================================================
import { Datepicker } from 'vanillajs-datepicker';

const pickers = document.querySelectorAll('.datePicker');

if (pickers) {
  pickers.forEach(function (item) {
    const datepicker = new Datepicker(item, {
      buttonClass: 'btn',
      format: 'dd.mm.yyyy'
    });
  });
}

import { DateRangePicker } from 'vanillajs-datepicker';

const rangePickers = document.querySelectorAll('.dateRangePicker');

if (rangePickers) {
  rangePickers.forEach(function (item) {
    const rangepicker = new DateRangePicker(item, {
      buttonClass: 'btn',
      format: 'dd.mm.yyyy'
    });
  });
}
// ==============================================================================================
// end of DATEPICKER


// FORM VALIDATION
// ==============================================================================================
(function () {

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  let forms = document.querySelectorAll('.needs-validation');

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})();
// ==============================================================================================
// end of FORM VALIDATION




// TOOLTIPS
// ==============================================================================================
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
window.__forceSmoothScrollPolyfill__ = true;

document
  .querySelectorAll('.scrollDown')
  .forEach(trigger => {
    trigger.onclick = function(e) {
      e.preventDefault();
      let hash = this.getAttribute('href');
      let target = document.querySelector(hash);
      let offsetPosition = target.offsetTop;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    };
  });
// ==============================================================================================
// end of TOOLTIPS



// TOOLTIPS
// ==============================================================================================
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
// ==============================================================================================
// end of TOOLTIPS



// WEB FONTS
// ==============================================================================================
let WebFont = require('webfontloader');

WebFont.load({
  google: {
    families: ['Montserrat:400,500,600,700,900']
  }
});
// ==============================================================================================
// end of WEB FONTS



// CAROUSELS
// ==============================================================================================
const FEATURESCAROUSEL = document.getElementById('featuresCarousel'),
      INVESTMENTSCAROUSEL = document.getElementById('investmentsCarousel'),
      PUBLICATIONSCAROUSEL = document.getElementById('publicationsCarousel');

const preventDefault = (e) => {
  e.preventDefault();
};

let investmentsCarouselGlide = new Glide('#investmentsCarousel', {
  gap: 40,
  perView: 1,
  type: 'slider'
});

let publicationsCarouselGlide = new Glide('#publicationsCarousel', {
  gap: 20,
  perView: 7,
  breakpoints: {
    1399: {
      perView: 5
    },
    1199: {
      perView: 5
    },
    991: {
      perView: 4
    },
    767: {
      perView: 3
    },
    575: {
      perView: 2
    }
  },
  type: 'carousel'
});

let featuresCarouselGlide = new Glide('#featuresCarousel', {
  gap: 60,
  perView: 1,
  type: 'carousel'
});

if (PUBLICATIONSCAROUSEL) {
  publicationsCarouselGlide.mount();
}

if (FEATURESCAROUSEL) {
  featuresCarouselGlide.mount();

  featuresCarouselGlide.on(['run.after'], function() {
    let slideIndex = FEATURESCAROUSEL.querySelector('.glide__bullet--active').dataset.index,
        indexEnd = FEATURESCAROUSEL.querySelector('.indexEnd'),
        indexStart = FEATURESCAROUSEL.querySelector('.indexStart'),
        indexQty = FEATURESCAROUSEL.querySelector('.indexEnd').dataset.indexQty;

    if (slideIndex !== '01') {
      indexStart.classList.remove('text-primary');
      indexStart.classList.add('text-secondary');

      indexEnd.innerHTML = slideIndex;

      indexEnd.classList.remove('text-secondary');
      indexEnd.classList.add('text-primary');
    } else {
      indexStart.classList.remove('text-secondary');
      indexStart.classList.add('text-primary');

      indexEnd.innerHTML = indexQty;

      indexEnd.classList.remove('text-primary');
      indexEnd.classList.add('text-secondary');
    }
  });
}

if (INVESTMENTSCAROUSEL) {
  investmentsCarouselGlide.mount();

  investmentsCarouselGlide.on(['run.after'], function() {
    let slideIndex = INVESTMENTSCAROUSEL.querySelector('.glide__bullet--active').dataset.index,
      indexEnd = INVESTMENTSCAROUSEL.querySelector('.indexEnd'),
      indexStart = INVESTMENTSCAROUSEL.querySelector('.indexStart'),
      indexQty = INVESTMENTSCAROUSEL.querySelector('.indexEnd').dataset.indexQty;

    if (slideIndex !== '01') {
      indexStart.classList.remove('text-primary');
      indexStart.classList.add('text-secondary');

      indexEnd.innerHTML = slideIndex;

      indexEnd.classList.remove('text-secondary');
      indexEnd.classList.add('text-primary');
    } else {
      indexStart.classList.remove('text-secondary');
      indexStart.classList.add('text-primary');

      indexEnd.innerHTML = indexQty;

      indexEnd.classList.remove('text-primary');
      indexEnd.classList.add('text-secondary');
    }
  });
}
// ==============================================================================================
// end of CAROUSELS
